import firebase from 'firebase/app'

import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

import { useEffect, useState } from 'react'

const firebaseConfig = JSON.parse(process.env.NEXT_PUBLIC_FB_CONFIG)

export const useFirebaseSetup = () => {
  const [initialized, setInitialized] = useState(firebase.apps.length !== 0)

  useEffect(() => {
    if (!initialized) {
      firebase.initializeApp(firebaseConfig)
      firebase.firestore().enablePersistence({ synchronizeTabs: true })

      setInitialized(true)
    }
  }, [initialized])

  return initialized
}