import { useMemo, useCallback } from 'react'

import { GeistProvider, CssBaseline } from '@geist-ui/react'
import { Page, Spacer } from '@geist-ui/react'

import Appbar from 'components/Appbar'
import Sidebar from 'components/Sidebar'
import PageLoader from 'components/PageLoader'

import 'utils/sentry'

import { useFirebaseSetup } from 'utils/hooks/firebase'

import { useUser, useLocalStorage, SidebarCollapseContext } from 'utils/hooks'
import { getSystemTheme } from 'utils/helpers'

import { FireProvider } from 'react-firebase-hooks-extended'

import '../styles/globals.css'
import '../styles/tailwind.css'

const Application = ({ Component, pageProps }) => {
  const { unauth, hideNav, forceAppBar, pageSize = 'large' } = pageProps

  const [themeType, switchThemes] = useLocalStorage('theme-type', 'system')
  const [sideBarCollapsed, setSideBarCollapsed] = useLocalStorage('sidebar-collapsed', false)

  const [user, loading] = useUser()

  const onCollapseSidebar = useCallback(event => {
    event.preventDefault()
    setSideBarCollapsed(!sideBarCollapsed)
  }, [sideBarCollapsed, setSideBarCollapsed])

  const sidebarCollapseValue = useMemo(() => {
    return {
      value: sideBarCollapsed,
      collapseSidebar: onCollapseSidebar,
    }
  }, [sideBarCollapsed, onCollapseSidebar])

  // TODO: better UX
  if (loading || (!user && !unauth)) return <PageLoader />

  const contentStyles = { padding: 0 }
  const pageStyles = { maxWidth: '100%' }

  return (
    <GeistProvider themeType={themeType === 'system' ? getSystemTheme() : themeType}>
      <CssBaseline />

      <SidebarCollapseContext.Provider value={sidebarCollapseValue}>
        {!hideNav && <Sidebar />}
        <div style={{ paddingLeft: hideNav ? 0 : sideBarCollapsed ? Sidebar.widthCollapsed : Sidebar.width }}>
          <Page size={pageSize} style={pageStyles}>
            {(!hideNav || forceAppBar) && <Appbar switchThemes={switchThemes} themeType={themeType} user={user} />}
            <Page.Content style={contentStyles}>
              <Component {...pageProps} user={user} />
            </Page.Content>
          </Page>
        </div>
        {!hideNav && <Spacer y={4} />}
      </SidebarCollapseContext.Provider>
    </GeistProvider>
  )
}

const FirebaseApplication = ({ children }) => useFirebaseSetup() ? children : <PageLoader />

export default function RootApplication(props) {
  return (
    <FirebaseApplication>
      <Application {...props} />
    </FirebaseApplication>
  )
}
