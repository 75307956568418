import { useState, useEffect } from 'react'

export default function SSRAware({ children }) {
  const [mounted, setMounted] = useState(false)
  
  // Wait until after client-side hydration to show
  useEffect(() => setMounted(true), [])

  // You can show some kind of placeholder UI here
  if (!mounted) return null

  return children
}