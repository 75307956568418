import { Avatar } from '@geist-ui/react'
import { getAvatarName, getCompanyName } from 'utils/helpers'

export default function User({ user, showName = true, showCompany = false, showEmail = false, disableSelect, size = 'small' }) {
  const { displayName, photoURL, email } = user
  const company = getCompanyName(email)

  const hasCompany = company !== 'Gmail'
  const isSmall = size === 'small'

  return (
    <div className={`flex items-center whitespace-no-wrap ${disableSelect ? 'select-none' : 'select-auto'} leading-none`}>
      <Avatar text={getAvatarName(displayName)} size={size} src={photoURL} />
      {showName && (
        <div className="ml-2">
          <div className={`${isSmall ? 'text-sm' : 'text-base'}`}>{displayName}</div>
          {showCompany && <div className="text-xs">{hasCompany ? company : 'Individual'}</div>}
          {showEmail && email && <div className="text-xs">{email}</div>}
        </div>
      )}
    </div>
  )
}