import { useState } from 'react'

import { useTheme } from '@geist-ui/react'
import { find } from 'lodash'

import { Row, Popover, Button, Spacer } from '@geist-ui/react'
import { Moon, Sun, ChevronUpDown, Check, PlusCircle, Plus } from '@geist-ui/react-icons'

import User from 'components/User'
import SSRAware from 'components/SSRAware'
import Link from 'components/Link'

import { useCurrentUser, useCurrentWorkspaceId, useLogout, useUserDocument } from 'utils/hooks'

const PopoverContent = ({ workspaces, close }) =>{
  const workspaceId = useCurrentWorkspaceId()
  const logout = useLogout()

  return (
    <>
      <div className="whitespace-nowrap" onClickCapture={close}>
        {workspaces.map(workspace => (
          <Popover.Item>
            <div /* Popover link jsx className ejected */ className="link-wrapper w-full">
              <Link href={`/w/${workspace.id}`} className="w-full">
                <div className="flex items-center justify-between w-full">
                  {workspace.name}
                  {workspaceId === workspace.id && <Check className="pl-3" />}
                </div>
              </Link>
            </div>
          </Popover.Item>
        ))}
        <Popover.Item>
          <Link href="/create-workspace">
            <div className="flex items-center">
              <PlusCircle className="pr-2" /> New workspace
            </div>
          </Link>
        </Popover.Item>
        <Popover.Item line />
        <Popover.Item>
          <Link href="/settings">Account settings</Link>
        </Popover.Item>
        <Popover.Item>
          <Link href="/signin" onClick={logout}>Logout</Link>
        </Popover.Item>
      </div>

      <style>{`
        .link-wrapper :global(.link) {
          width: 100%;
        }
      `}
      </style>
    </>
  )
}

const Workspaces = () => {
  const theme = useTheme()
  const user = useCurrentUser()
  const [userDocument, loading] = useUserDocument()
  const workspaceId = useCurrentWorkspaceId()

  const [visible, setVisible] = useState(false)

  if (loading) return null

  const workspaces = userDocument.get('workspaces')
  const workspace = find(workspaces, ['id', workspaceId])

  return (
    <Popover content={<PopoverContent workspaces={workspaces} close={() => setVisible(false)} />} visible={visible} onVisibleChange={setVisible}>
      <div /* Popover jsx className ejected */>
        <div className="workspace transition-colors rounded flex items-center cursor-pointer p-2">
          <User user={{ ...user, email: `@${workspace?.name}.com` }} size={42} showCompany />
          <div className="ml-4">
            <ChevronUpDown />
          </div>
        </div>
      </div>

      <style jsx>{`
        .workspace:hover {
          background-color: ${theme.palette.accents_1};
        }
      `}</style>
    </Popover>
  )
}

export default function Appbar({ switchThemes }) {
  const theme = useTheme()
  const user = useCurrentUser()

  const isDark = theme.type === 'dark'
  const ThemeIcon = isDark ? Moon : Sun

  const toggleDarkMode = () => switchThemes(isDark ? 'light' : 'dark')

  return (
    <>
      <nav className="flex h-16 items-center justify-between">
        <div />

        <Row align="middle">
          {user && (
            <SSRAware>
              <Workspaces />
            </SSRAware>
          )}

          <Spacer inline x={.5} />

          <Button size="small" auto type="abort" icon={<ThemeIcon size={16} />} onClick={toggleDarkMode} />
        </Row>
      </nav>
    </>
  )
}