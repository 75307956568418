import NextLink from 'next/link'
import { useRouter } from 'next/router'

import { startsWith } from 'lodash'

import { Link, Spacer, Divider, Row, useTheme } from '@geist-ui/react'
import { Settings, Pocket, Columns, Sidebar as SidebarIcon, Mail, Users } from '@geist-ui/react-icons'

import { useSidebarCollapse, useIsPersonalWorkspace, useCurrentWorkspaceId } from 'utils/hooks'


const NavLink = ({ href, active, children }) => {
  return (
    <NextLink href={href}><Link color={active} href={href}>{children}</Link></NextLink>
  )
}

const SidebarItem = ({ href, icon, external, onClick, children }) => {
  const isPersonalWorkspace = useIsPersonalWorkspace()
  const workspaceId = useCurrentWorkspaceId()

  const [sideBarCollapsed] = useSidebarCollapse()
  const { palette } = useTheme()
  const router = useRouter()

  href = isPersonalWorkspace || startsWith(href, 'mail') ? href : `/w/${workspaceId}${href}`

  const { pathname, asPath } = router

  const active = pathname === href || asPath === href
  const rowStyles = { ...(active && { backgroundColor: palette.success }) }

  return (
    <Row className="px-1" style={{} || rowStyles} onClickCapture={onClick}>
      <NavLink href={href} active={active}>
        <div className="flex items-center">
          {!!icon && (
            <>
              {icon}
              {!sideBarCollapsed && <Spacer inline x={.6} />}
            </>
          )}
          {!sideBarCollapsed && children}
        </div>
      </NavLink>
    </Row>
  )
}

const Sidebar = () => {
  const isPersonalWorkspace = useIsPersonalWorkspace()
  const [sideBarCollapsed, onCollapseSidebar] = useSidebarCollapse()
  const theme = useTheme()
  const isDark = theme.type === 'dark'

  return (
    <nav
      className={`fixed top-0 bottom-0 left-0 z-10 flex flex-col justify-between ${sideBarCollapsed ? 'px-3' : 'px-6'}`}
      style={{ backgroundColor: theme.palette.accents_1, width: sideBarCollapsed ? Sidebar.widthCollapsed : Sidebar.width  }}
    >
      <div>
        <div className="py-4" key={`${sideBarCollapsed}_${isDark}`}>
          <NavLink href="/">
            {sideBarCollapsed ? (
              <img className="inline-block" src={`/logos/Vectors/Logomark - ${!isDark ? 'White' : 'Black'}.svg`} width="100%" />
            ) : (
              <img className="inline-block" src={`/logos/Vectors/Logo - Alternate Layout - ${!isDark ? 'Black' : 'White'}.svg`} width="190" />
            )}
          </NavLink>
        </div>

        <Spacer y={1.2} />

        <>
          <SidebarItem href="/" icon={<Columns />}>Boards</SidebarItem>
          <Spacer y={1} />
          <SidebarItem href="/actions" icon={<Pocket />}>Action notes</SidebarItem>
          {!isPersonalWorkspace && (
            <>
              <Spacer y={1} />
              <SidebarItem href="/members" icon={<Users />}>Members</SidebarItem>
            </>
          )}
        </>

        <Divider />

        <>
          <SidebarItem href="/settings" icon={<Settings />}>Settings</SidebarItem>
          <Spacer y={1} />
          <SidebarItem href="mailto:support@backdated.io?subject=Application feedback" icon={<Mail />}>Contact support</SidebarItem>
        </>

      </div>

      <div>
        <Divider /> 
        <SidebarItem onClick={onCollapseSidebar} href="/#" icon={<SidebarIcon />}>Collapse Sidebar</SidebarItem>
        <Spacer y={1} />
      </div>
    </nav>
  )
}

Sidebar.width = 240
Sidebar.widthCollapsed = 56

export default Sidebar
